export enum OperationType {
  PAYMENT = "payment",
  PAYOUT = "payout",
}

export enum EPayStatus {
  TEST,
  FORM,
  SUCCESS,
  FAIL,
  PROCESS,
  PAYOUT,
  P2P_FORM,
  PAYOUT_CRYPTO = "coin",
  H2H_P2P = "h2h_p2p",
}

export enum EModalType {
  TERMS,
  POLICY,
}

export enum EIntegrationType {
  H2H = "h2h",
  P2P_CARD = "p2p_card",
  REDIRECT = "redirect",
  H2H_P2P = "h2h_p2p",
}
