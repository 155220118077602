import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, createCommentVNode as _createCommentVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["disabled", "type"]
const _hoisted_2 = {
  key: 1,
  alt: "loading",
  class: "button__img",
  src: "/button-spinner.png"
}

import { PropType } from "vue";

type TButtonType = "button" | "submit";


export default /*@__PURE__*/_defineComponent({
  __name: 'VButton',
  props: {
  type: {
    type: String as PropType<TButtonType>,
    default: "button",
  },
  disabled: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  loading: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  transparent: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass([{ 'button--loading': __props.loading, 'button--transparent': __props.transparent }, "button"]),
    disabled: __props.disabled,
    type: __props.type
  }, [
    (!__props.loading)
      ? _renderSlot(_ctx.$slots, "default", { key: 0 })
      : (_openBlock(), _createElementBlock("img", _hoisted_2))
  ], 10, _hoisted_1))
}
}

})