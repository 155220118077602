<template>
  <!--  IMPORTANT!
        v-model="value" - didn't work on Android. https://github.com/vuejs/vue/issues/9777#issuecomment-478831263
  -->
  <input
    class="input"
    :value="value"
    placeholder="Имя на карте"
    @keypress="handleOnKeyPress"
    @paste="handleOnPaste"
    @input="handleOnChange"
  />
</template>

<script lang="ts" setup>
import { computed, PropType } from "vue";

const props = defineProps({
  modelValue: {
    type: String as PropType<string>,
    default: "",
  },
});

const emit = defineEmits(["update:modelValue"]);

const value = computed<string>({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const validateLatin = (e: KeyboardEvent | ClipboardEvent, value: string) => {
  if (/^[A-Za-z\s]+$/.test(value)) return true;
  else e.preventDefault();
};

const handleOnKeyPress = (e: KeyboardEvent) =>
  validateLatin(e, String.fromCharCode(e.keyCode));

const handleOnPaste = (e: ClipboardEvent) =>
  validateLatin(e, (e.clipboardData as DataTransfer).getData("text"));

const handleOnChange = (e: InputEvent) => {
  value.value = (e.target as HTMLInputElement).value.replace(
    /[^A-Za-z\s]/g,
    ""
  );
};
</script>

<style lang="scss">
@import "input";
</style>
