import { AxiosInstance } from "axios";
import { Payment } from "@/services/api/Payment";
import { Payout } from "@/services/api/Payout";

export const defineApi = (instance: AxiosInstance) => {
  return () => ({
    payment: new Payment(instance),
    payout: new Payout(instance),
  });
};
