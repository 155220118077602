import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "base-container" }

import TheFooter from "@/components/TheFooter.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'BaseContainer',
  props: {
    support: { type: Boolean, default: false }
  },
  setup(__props: any) {




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass({
        'base-container__wrapper': true,
        'base-container__wrapper--support': _ctx.support,
      })
    }, [
      _createVNode(_Transition, {
        mode: "out-in",
        name: "appear"
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      })
    ], 2),
    false
      ? (_openBlock(), _createBlock(TheFooter, {
          key: 0,
          class: "base-container__footer"
        }))
      : _createCommentVNode("", true)
  ]))
}
}

})