import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "appeal-status" }
const _hoisted_2 = { class: "appeal-status__container" }
const _hoisted_3 = { class: "appeal-status__number" }
const _hoisted_4 = { class: "appeal-status__btns" }
const _hoisted_5 = ["href"]

import InfoBlock from "@/components/InfoBlock.vue";
import VDivider from "@/components/VDivider.vue";
import { computed, PropType } from "vue";
import { TAppeal, TPaymentResponse } from "@/types/payment";
import VButton from "@/components/VButton.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'H2HP2PAppealStatus',
  props: {
  request: {
    type: Object as PropType<TPaymentResponse>,
    default: null,
  },
  appeal: {
    type: Object as PropType<TAppeal>,
    required: true,
  },
},
  emits: ["close"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const appealStatuses = {
  create: {
    title: "Ваша заявка создана",
    type: "warning",
  },
  process: {
    title: "Ваша заявка в обработке",
    type: "warning",
  },
  success: {
    title: "Ваша заявка успешно обработана",
    type: "success",
  },
  reversal: {
    title: "Ваша заявка отменена",
    type: "error",
  },
};

const appealStatus = computed(() => appealStatuses[props.appeal.status]);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("h2", { class: "appeal-status__title" }, "Обращение в службу поддержки", -1)),
      _cache[3] || (_cache[3] = _createElementVNode("span", { class: "appeal-status__payment-number" }, " Номер платежа ", -1)),
      _createElementVNode("p", _hoisted_3, _toDisplayString(__props.request.info.tx), 1),
      _cache[4] || (_cache[4] = _createElementVNode("p", { class: "appeal-status__text" }, "Статус заявки", -1)),
      _createVNode(InfoBlock, {
        title: appealStatus.value.title,
        type: appealStatus.value.type
      }, null, 8, ["title", "type"]),
      _createVNode(VDivider, { class: "appeal-status__divider" }),
      _createElementVNode("div", _hoisted_4, [
        (__props.request?.info?.payment?.fail_url)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              href: __props.request.info.payment.fail_url,
              class: "button"
            }, " Вернуться в магазин ", 8, _hoisted_5))
          : _createCommentVNode("", true),
        _createVNode(VButton, {
          transparent: "",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('close')))
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode(" Вернуться к платежу ")
          ])),
          _: 1
        })
      ])
    ])
  ]))
}
}

})