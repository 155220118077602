<template>
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clip-rule="evenodd"
      d="M7.02 1.52a2 2 0 0 1 2.69.7v.01l5.65 9.43a2 2 0 0 1-1.7 3H2.34a2 2 0 0 1-1.71-3L6.3 2.23l.57.34-.57-.34a2 2 0 0 1 .73-.7Zm.41 1.4L1.8 12.34a.67.67 0 0 0 .57 1h11.28a.67.67 0 0 0 .57-1L8.57 2.92a.67.67 0 0 0-1.14 0Z"
      fill="currentColor"
      fill-rule="evenodd"
    />
    <path
      clip-rule="evenodd"
      d="M8 5.33c.37 0 .67.3.67.67v2.67a.67.67 0 0 1-1.34 0V6c0-.37.3-.67.67-.67Z"
      fill="currentColor"
      fill-rule="evenodd"
    />
    <path
      clip-rule="evenodd"
      d="M7.33 11.33c0-.36.3-.66.67-.66A.67.67 0 0 1 8 12a.67.67 0 0 1-.67-.67Z"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script lang="ts" setup></script>

<style lang="scss"></style>
