<template>
  <input
    ref="inputCard"
    v-model="value"
    v-cardformat:formatCardNumber
    class="input input--card"
    placeholder="Номер карты"
    @input="handleOnChange"
  />
</template>

<script lang="ts" setup>
import { computed, getCurrentInstance, onMounted, PropType, ref } from "vue";

const props = defineProps({
  modelValue: {
    type: String as PropType<string>,
    default: "",
  },
});

const emit = defineEmits(["update:modelValue", "update:cardType"]);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const inputCard = ref<HTMLInputElement | null>(null);
const app = getCurrentInstance();
const cardFormat = app?.appContext?.config?.globalProperties?.$cardFormat;

const handleOnChange = () => {
  if (inputCard.value) {
    cardFormat.setCardType({
      currentTarget: inputCard.value,
      value,
    });
    let cardType = inputCard.value?.dataset?.cardBrand ?? "unknown";
    if (cardType === "Humo" || cardType === "Uzcard") {
      cardType = "unknown";
    }
    // TODO: find svgs for these cards
    emit("update:cardType", cardType);
  }
};
onMounted(handleOnChange);
</script>

<style lang="scss">
@import "input";
</style>
