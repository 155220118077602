import { defineComponent as _defineComponent } from 'vue'
import { vModelText as _vModelText, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed, PropType } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InputExpired',
  props: {
  modelValue: {
    type: String as PropType<string>,
    default: "",
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

return (_ctx: any,_cache: any) => {
  const _directive_cardformat = _resolveDirective("cardformat")!

  return _withDirectives((_openBlock(), _createElementBlock("input", {
    class: "input",
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
    placeholder: "MM / YY"
  }, null, 512)), [
    [_vModelText, value.value],
    [_directive_cardformat, void 0, "formatCardExpiry"]
  ])
}
}

})