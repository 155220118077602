<template>
  <div class="input-wrapper">
    <input
      ref="cvv"
      v-model="value"
      v-cardformat:formatCardCVC
      :type="type"
      class="input input--cvv"
      placeholder="CVC"
    />
    <button
      v-if="value !== ''"
      :class="{ visible: type === 'text' }"
      class="input-wrapper__toggle"
      type="button"
      @click="handleOnToggleType"
    >
      <svg>
        <use xlink:href="#eye" />
      </svg>
    </button>
  </div>
</template>

<script lang="ts" setup>
import { computed, PropType, ref, nextTick } from "vue";

const props = defineProps({
  modelValue: {
    type: String as PropType<string>,
    default: "",
  },
});

const emit = defineEmits(["update:modelValue"]);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const type = ref<"text" | "password">("password");
const cvv = ref<HTMLInputElement | null>(null);

const focusToEndOfInput = async () => {
  cvv.value?.setSelectionRange(cvv.value.value.length, cvv.value.value.length);
  await nextTick();
  cvv.value?.focus();
};

const handleOnToggleType = () => {
  type.value = type.value === "text" ? "password" : "text";
  focusToEndOfInput();
};
</script>

<style lang="scss">
@import "input";

.input-wrapper {
  position: relative;

  &__toggle {
    all: unset;
    position: absolute;
    top: 50%;
    right: 32px;
    display: grid;
    place-content: center;
    width: 30px;
    height: 48px;
    transform: translateY(-50%);
    cursor: pointer;

    svg {
      width: 24px;
      height: 24px;
    }

    &.visible {
      &:after {
        position: absolute;
        top: 24px;
        left: 7px;
        display: block;
        width: 16px;
        height: 1px;
        background: black;
        transform: rotate(45deg);
        content: "";
      }
    }
  }
}
</style>
