import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "form__container form__container--payout" }

import { TPayoutForm, TPayoutResponse } from "@/types/payout";
import { PropType, reactive, ref } from "vue";
import { useApi } from "@/plugins/api";
import { usePrice } from "@/use/usePrice";
import { useValidation } from "@/use/useValidation";
import { OperationType } from "@/helpers/enums";
import VButton from "@/components/VButton.vue";
import InputWrapper from "@/components/inputs/InputWrapper.vue";
import InputCrypto from "@/components/inputs/InputCrypto.vue";
import VDivider from "@/components/VDivider.vue";
import OperationWrapper from "@/components/OperationWrapper.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CryptoPayoutForm',
  props: {
  request: {
    type: Object as PropType<TPayoutResponse>,
    default: null,
  },
},
  emits: ["process", "fail"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const price = usePrice(
  props.request?.tx?.payout?.amount ?? 0,
  props.request?.tx?.payout?.currency?.char_code
);

const disabled = ref<boolean>(false);
const isLoading = ref<boolean>(false);
const form = reactive<TPayoutForm>({
  tx: props.request.tx.tx,
  account_number: "",
  payment_method: props.request.tx.payout.means_of_payment_type.code as string,
});
const { payout } = useApi();

const { validFields, isCryptoPayoutFormValid, validateCryptoWallet } =
  useValidation();

const handleOnSubmit = async () => {
  disabled.value = true;
  isLoading.value = true;

  try {
    await payout.process(form);
    emit("process");
  } catch (_) {
    emit("fail");
  } finally {
    disabled.value = false;
    isLoading.value = false;
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(OperationWrapper, {
    price: _unref(price),
    type: _unref(OperationType).PAYOUT
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        class: "form",
        onSubmit: _withModifiers(handleOnSubmit, ["prevent"])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(InputWrapper, {
            condition: _unref(validFields).account_number,
            class: "form__input"
          }, {
            default: _withCtx(() => [
              _createVNode(InputCrypto, {
                modelValue: form.account_number,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.account_number) = $event)),
                onInput: _cache[1] || (_cache[1] = ($event: any) => (_unref(validateCryptoWallet)(form.account_number)))
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["condition"]),
          _cache[2] || (_cache[2] = _createElementVNode("p", { class: "form__addition-text" }, [
            _createElementVNode("sup", null, "*"),
            _createTextVNode("Поддерживаемая сеть - ERC20, TRC20 ")
          ], -1))
        ]),
        _createVNode(VDivider, { class: "divider-section" }),
        (__props.request)
          ? (_openBlock(), _createBlock(VButton, {
              key: 0,
              disabled: disabled.value || !_unref(isCryptoPayoutFormValid),
              loading: isLoading.value,
              class: "form__submit form__submit--crypto",
              type: "submit"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Вывести " + _toDisplayString(_unref(price)), 1)
              ]),
              _: 1
            }, 8, ["disabled", "loading"]))
          : _createCommentVNode("", true)
      ], 32)
    ]),
    _: 1
  }, 8, ["price", "type"]))
}
}

})