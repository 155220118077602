<template>
  <footer class="footer">
    <button class="footer__link" @click="onOpen(EModalType.TERMS)">
      Terms
    </button>
    <button class="footer__link" @click="onOpen(EModalType.POLICY)">
      Privacy
    </button>
  </footer>
</template>

<script lang="ts" setup>
import { EModalType } from "@/helpers/enums";
import { useModal } from "@/use/useModal";

const { onOpen } = useModal();
</script>

<style lang="scss">
@import "@scss/utils.scss";

.footer {
  display: grid;
  grid-auto-flow: column;
  justify-content: center;
  column-gap: 14px;

  &__link {
    color: $alter-text;
    font-size: 12px;
    line-height: 14px;
    text-decoration-line: underline;

    @include hover() {
      text-decoration: none;
    }
  }
}
</style>
