<template>
  <div class="operation-result">
    <svg
      :class="`operation-result__icon--${
        status === EPayStatus.SUCCESS ? 'success' : 'fail'
      }`"
      class="operation-result__icon"
    >
      <use :xlink:href="resultInformation[status].icon" />
    </svg>
    <span class="operation-result__title">
      {{ resultInformation[status].title }}
    </span>
    <v-divider v-if="url" class="divider-section" />
    <a v-if="url" :href="url" class="button operation-result__link">
      {{ resultInformation[status].linkText }}
    </a>
  </div>
</template>

<script lang="ts" setup>
import { PropType } from "vue";
import { EPayStatus } from "@/helpers/enums";
import { OperationType } from "@/helpers/enums";
import VDivider from "@/components/VDivider.vue";

const props = defineProps({
  url: {
    type: String as PropType<string>,
    default: "",
  },
  type: {
    type: String as PropType<OperationType>,
    default: OperationType.PAYMENT,
  },
  status: {
    type: Number as PropType<EPayStatus>,
    default: EPayStatus.SUCCESS,
  },
});

type TResult = {
  [key: number]: {
    icon: string;
    title: string;
    linkText: string;
  };
};

const resultInformation: TResult = {
  [EPayStatus.SUCCESS]: {
    icon: "#success-payment",
    title: props.type === OperationType.PAYMENT ? "Оплачено" : "Выплачено",
    linkText: "Вернуться в магазин",
  },
  [EPayStatus.FAIL]: {
    icon: "#fail-payment",
    title: `Ошибка ${
      props.type === OperationType.PAYMENT ? "оплаты" : "выплаты"
    }`,
    linkText: "Повторить",
  },
};
</script>
<style lang="scss" scoped>
@import "@scss/utils.scss";

.operation-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 18px;
  text-align: center;

  @include mq($sz-mobile) {
    flex-grow: 1;
    margin-top: 50%;
  }

  &__icon {
    margin-bottom: 46px;

    &--success {
      width: 66px;
      height: 48px;
      animation: pops 1s ease-in-out;
    }

    &--fail {
      width: 52px;
      height: 52px;
      animation: headShake 1s ease-in-out;
    }
  }

  &__title {
    @include font-template(Poppins, 32px, 40px, 500);
  }

  &__link {
    @include mq($sz-mobile) {
      margin-top: auto;
    }
  }
}

@keyframes pops {
  from {
    transform: translateY(0) scale(1);
  }
  50% {
    transform: translateY(-5px) scale(1.1);
  }
  to {
    transform: translateY(0) scale(1);
  }
}

@keyframes headShake {
  0% {
    transform: translateX(0);
  }

  6.5% {
    transform: translateX(-6px) rotateY(-9deg);
  }

  18.5% {
    transform: translateX(5px) rotateY(7deg);
  }

  31.5% {
    transform: translateX(-3px) rotateY(-5deg);
  }

  43.5% {
    transform: translateX(2px) rotateY(3deg);
  }

  50% {
    transform: translateX(0);
  }
}
</style>
