import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "footer" }

import { EModalType } from "@/helpers/enums";
import { useModal } from "@/use/useModal";


export default /*@__PURE__*/_defineComponent({
  __name: 'TheFooter',
  setup(__props) {

const { onOpen } = useModal();

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("footer", _hoisted_1, [
    _createElementVNode("button", {
      class: "footer__link",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(onOpen)(_unref(EModalType).TERMS)))
    }, " Terms "),
    _createElementVNode("button", {
      class: "footer__link",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(onOpen)(_unref(EModalType).POLICY)))
    }, " Privacy ")
  ]))
}
}

})