import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "card-types" }
const _hoisted_2 = ["xlink:href"]


export default /*@__PURE__*/_defineComponent({
  __name: 'CardTypes',
  setup(__props) {

const cardTypes: string[] = [
  "card-world",
  "master-card",
  "visa-card",
  "pci-card",
];

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock(_Fragment, null, _renderList(cardTypes, (card, i) => {
      return _createElementVNode("svg", {
        key: i,
        class: "card-types__icon"
      }, [
        _createElementVNode("use", {
          "xlink:href": `#${card}`
        }, null, 8, _hoisted_2)
      ])
    }), 64))
  ]))
}
}

})