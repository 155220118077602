<template>
  <div class="operation-process">
    <div class="operation-process__icon">
      <img alt="loader" src="/spinner.png" />
    </div>
    <span class="operation-process__title">Платеж в обработке</span>
    <v-countdown
      v-if="countdown"
      :date-to="countdown"
      class="operation-process__countdown"
    />
    <VButton
      v-if="!hasAppeals"
      class="operation-process__link"
      @click="emit('openAppeal')"
    >
      Обратиться в поддержку
    </VButton>
    <VButton
      v-else
      class="operation-process__link"
      @click="emit('openAppealStatus')"
    >
      Посмотреть статус апелляции
    </VButton>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, PropType } from "vue";
import VCountdown from "@/components/VCountdown.vue";
import VButton from "@/components/VButton.vue";

defineProps({
  url: {
    type: String as PropType<string>,
    default: "",
  },
  countdown: {
    type: String as PropType<string>,
    default: "",
  },
  hasAppeals: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits<{
  (e: "startInterval"): void;
  (e: "openAppeal"): void;
  (e: "openAppealStatus"): void;
}>();

onMounted(() => {
  emit("startInterval");
});
</script>

<style lang="scss" scoped>
@import "@scss/utils.scss";

.operation-process {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 18px;
  text-align: center;

  @include mq($sz-mobile) {
    flex-grow: 1;
    margin-top: 50%;
  }

  &__icon {
    width: 52px;
    height: 52px;
    margin-bottom: 46px;

    > img {
      animation: spin 1s infinite linear, fadeOut 4s infinite linear 2s;
    }
  }

  &__title {
    @include font-template(Poppins, 32px, 40px, 500);
  }

  &__countdown {
    margin-top: 24px;
  }

  &__link {
    margin-top: 24px;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  12.5% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
}
</style>
