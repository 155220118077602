import axios, { AxiosRequestConfig, AxiosRequestHeaders } from "axios";
import { defineApi } from "@/services/api";
import { App } from "vue";

const API_URL = process.env.VUE_APP_API_URL || "";
const baseURL = `${API_URL}/api/v1/`;

const token = localStorage.getItem("token") || "";
const apiInstance = axios.create({
  headers: {
    "Content-Type": "application/json",
    Authorization: token,
    site: process.env.SITE || "zenbet",
  },
  withCredentials: false,
  baseURL,
});

apiInstance.interceptors.request.use(function (config: AxiosRequestConfig) {
  if (config.url !== "auth/login/") {
    (config.headers as AxiosRequestHeaders).Authorization =
      localStorage.getItem("token") ?? "";
  }
  return config;
});

export const useApi = defineApi(apiInstance);

export default {
  install: (app: App) => {
    app.config.globalProperties.$api = useApi();
  },
};
