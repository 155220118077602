import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "price-header" }
const _hoisted_2 = { class: "price-header__label" }
const _hoisted_3 = { class: "price-header__price" }

import { PropType } from "vue";
import { OperationType } from "@/helpers/enums";


export default /*@__PURE__*/_defineComponent({
  __name: 'PriceHeader',
  props: {
  price: {
    type: String as PropType<string>,
    default: "",
  },
  type: {
    type: String as PropType<OperationType>,
    default: OperationType.PAYMENT,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, " Сумма к " + _toDisplayString(__props.type === _unref(OperationType).PAYMENT ? "оплате" : "выводу"), 1),
    _createElementVNode("span", _hoisted_3, _toDisplayString(__props.price), 1)
  ]))
}
}

})