import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "operation-process" }

import { onMounted, PropType } from "vue";
import VCountdown from "@/components/VCountdown.vue";
import VButton from "@/components/VButton.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'OperationProcess',
  props: {
  url: {
    type: String as PropType<string>,
    default: "",
  },
  countdown: {
    type: String as PropType<string>,
    default: "",
  },
  hasAppeals: {
    type: Boolean,
    default: false,
  },
},
  emits: ["startInterval", "openAppeal", "openAppealStatus"],
  setup(__props, { emit: __emit }) {



const emit = __emit;

onMounted(() => {
  emit("startInterval");
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "operation-process__icon" }, [
      _createElementVNode("img", {
        alt: "loader",
        src: "/spinner.png"
      })
    ], -1)),
    _cache[5] || (_cache[5] = _createElementVNode("span", { class: "operation-process__title" }, "Платеж в обработке", -1)),
    (__props.countdown)
      ? (_openBlock(), _createBlock(VCountdown, {
          key: 0,
          "date-to": __props.countdown,
          class: "operation-process__countdown"
        }, null, 8, ["date-to"]))
      : _createCommentVNode("", true),
    (!__props.hasAppeals)
      ? (_openBlock(), _createBlock(VButton, {
          key: 1,
          class: "operation-process__link",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('openAppeal')))
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode(" Обратиться в поддержку ")
          ])),
          _: 1
        }))
      : (_openBlock(), _createBlock(VButton, {
          key: 2,
          class: "operation-process__link",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (emit('openAppealStatus')))
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode(" Посмотреть статус апелляции ")
          ])),
          _: 1
        }))
  ]))
}
}

})