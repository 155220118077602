import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal__title" }
const _hoisted_2 = { class: "modal__body" }

import { onMounted, onUnmounted } from "vue";
import TheFooter from "@/components/TheFooter.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'TheModal',
  emits: ["hide"],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const handleOnHide = () => emit("hide");

onMounted(() => {
  document.getElementsByTagName("html")[0].classList.add("modal-lock");
});

onUnmounted(() => {
  document.getElementsByTagName("html")[0].classList.remove("modal-lock");
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal",
    onClick: _withModifiers(handleOnHide, ["self"])
  }, [
    _createElementVNode("div", {
      class: "modal__container",
      onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
    }, [
      _createElementVNode("button", {
        class: "modal__close",
        onClick: handleOnHide
      }, _cache[1] || (_cache[1] = [
        _createElementVNode("svg", null, [
          _createElementVNode("use", { "xlink:href": "#close" })
        ], -1)
      ])),
      _createElementVNode("div", _hoisted_1, [
        _renderSlot(_ctx.$slots, "title")
      ]),
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default")
      ]),
      _createVNode(TheFooter)
    ])
  ]))
}
}

})