<template>
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clip-rule="evenodd"
      d="M12 3a9 9 0 1 0 0 18 9 9 0 0 0 0-18ZM1 12a11 11 0 1 1 22 0 11 11 0 0 1-22 0Z"
      fill="currentColor"
      fill-rule="evenodd"
    />
    <path
      clip-rule="evenodd"
      d="M12 11c.6 0 1 .4 1 1v4a1 1 0 1 1-2 0v-4c0-.6.4-1 1-1Z"
      fill="currentColor"
      fill-rule="evenodd"
    />
    <path
      clip-rule="evenodd"
      d="M11 8c0-.6.4-1 1-1a1 1 0 1 1 0 2 1 1 0 0 1-1-1Z"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
</template>

<script setup lang="ts"></script>

<style lang="scss"></style>
