import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "countdown" }
const _hoisted_2 = { class: "countdown__time" }

import { ref, onMounted, onUnmounted } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'VCountdown',
  props: {
  dateTo: {
    type: String,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const timeLeft = ref({
  hours: "00",
  minutes: "00",
  seconds: "00",
});

let timer: number;

const formatNumber = (x: number) => (x < 10 ? `0${x}` : `${x}`);
const updateTime = () => {
  const endDate = new Date(props.dateTo + "Z").getTime(); // Adding 'Z' to indicate UTC time

  const now = new Date().getTime();
  let difference = endDate - now;

  if (difference < 0) {
    clearInterval(timer);
    difference = 0;
  }

  timeLeft.value = {
    hours: formatNumber(
      Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    ),
    minutes: formatNumber(
      Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
    ),
    seconds: formatNumber(Math.floor((difference % (1000 * 60)) / 1000)),
  };
};

onMounted(() => {
  updateTime();
  timer = setInterval(updateTime, 1000);
});

onUnmounted(() => {
  clearInterval(timer);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(timeLeft.value.minutes) + "m " + _toDisplayString(timeLeft.value.seconds) + "s ", 1)
  ]))
}
}

})