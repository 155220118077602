import { defineComponent as _defineComponent } from 'vue'
import { vModelText as _vModelText, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { computed, getCurrentInstance, onMounted, PropType, ref } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InputCard',
  props: {
  modelValue: {
    type: String as PropType<string>,
    default: "",
  },
},
  emits: ["update:modelValue", "update:cardType"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const inputCard = ref<HTMLInputElement | null>(null);
const app = getCurrentInstance();
const cardFormat = app?.appContext?.config?.globalProperties?.$cardFormat;

const handleOnChange = () => {
  if (inputCard.value) {
    cardFormat.setCardType({
      currentTarget: inputCard.value,
      value,
    });
    let cardType = inputCard.value?.dataset?.cardBrand ?? "unknown";
    if (cardType === "Humo" || cardType === "Uzcard") {
      cardType = "unknown";
    }
    // TODO: find svgs for these cards
    emit("update:cardType", cardType);
  }
};
onMounted(handleOnChange);

return (_ctx: any,_cache: any) => {
  const _directive_cardformat = _resolveDirective("cardformat")!

  return _withDirectives((_openBlock(), _createElementBlock("input", {
    ref_key: "inputCard",
    ref: inputCard,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
    class: "input input--card",
    placeholder: "Номер карты",
    onInput: handleOnChange
  }, null, 544)), [
    [_vModelText, value.value],
    [_directive_cardformat, void 0, "formatCardNumber"]
  ])
}
}

})