import { ApiService } from "@/services/api/ApiService";
import {
  TAppeal,
  TAppealForm,
  TAppealResponse,
  TApproveForm,
  TBank,
  TH2HP2PPaymentForm,
  TPaymentCreationForm,
  TPaymentForm,
  TPaymentResponse,
} from "@/types/payment";
import { TNullable } from "@/types/common";

export class Payment extends ApiService {
  public pay(form: TPaymentForm) {
    return this.instance.post<TPaymentResponse>(
      "paymentgate/payment/process/",
      {
        ...form,
        expire: this.prepareExpire(form?.expire),
        pan: form.pan?.replace(/\D+/g, ""),
      }
    );
  }

  public payP2P(form: TPaymentForm) {
    return this.instance.post<TPaymentResponse>(
      "paymentgate/payment/process/",
      { ...form, pan: form.pan?.replace(/\D+/g, "") }
    );
  }

  public payWithTx(tx: string) {
    return this.instance.post<TPaymentResponse>(
      "paymentgate/payment/process/",
      {
        tx,
      }
    );
  }

  public createPayment(form: TPaymentCreationForm) {
    return this.instance.post<TPaymentResponse>(
      "paymentgate/payment/simple/",
      form
    );
  }

  public info(tx: string) {
    return this.instance.get<TPaymentResponse>("paymentgate/payment/info/", {
      params: {
        tx,
      },
    });
  }

  public banks(tx: string) {
    return this.instance.get<TBank[]>(`paymentgate/payment/${tx}/banks/`);
  }

  public processP2P(form: TH2HP2PPaymentForm) {
    return this.instance.post<TPaymentResponse>(
      "paymentgate/payment/process_p2p/",
      form
    );
  }

  public approveP2P(form: TApproveForm) {
    return this.instance.post<TPaymentResponse>(
      "paymentgate/payment/approve_p2p/",
      form
    );
  }

  public createAppeal(form: TAppealForm) {
    const fd = new FormData();
    Object.entries(form).forEach(([key, value]) => {
      if (key === "receipt") fd.append(key, value, (value as File).name);
      else fd.append(key, value);
    });
    return this.instance.post<TAppealResponse>(
      "paymentgate/transaction/appeal/",
      fd
    );
  }

  public appeals(tx: string) {
    return this.instance.get<TAppeal[]>(
      `paymentgate/transaction/appeals/${tx}/`
    );
  }

  private prepareExpire(expire: TNullable<string>) {
    const expireDate = expire?.replace(/\D+/g, "");
    return expireDate?.length === 6
      ? expireDate.slice(0, 2) + expireDate.slice(-2)
      : expireDate;
  }
}
