import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "operation-result" }
const _hoisted_2 = ["xlink:href"]
const _hoisted_3 = { class: "operation-result__title" }
const _hoisted_4 = ["href"]

import { PropType } from "vue";
import { EPayStatus } from "@/helpers/enums";
import { OperationType } from "@/helpers/enums";
import VDivider from "@/components/VDivider.vue";

type TResult = {
  [key: number]: {
    icon: string;
    title: string;
    linkText: string;
  };
};


export default /*@__PURE__*/_defineComponent({
  __name: 'OperationResult',
  props: {
  url: {
    type: String as PropType<string>,
    default: "",
  },
  type: {
    type: String as PropType<OperationType>,
    default: OperationType.PAYMENT,
  },
  status: {
    type: Number as PropType<EPayStatus>,
    default: EPayStatus.SUCCESS,
  },
},
  setup(__props) {

const props = __props;

const resultInformation: TResult = {
  [EPayStatus.SUCCESS]: {
    icon: "#success-payment",
    title: props.type === OperationType.PAYMENT ? "Оплачено" : "Выплачено",
    linkText: "Вернуться в магазин",
  },
  [EPayStatus.FAIL]: {
    icon: "#fail-payment",
    title: `Ошибка ${
      props.type === OperationType.PAYMENT ? "оплаты" : "выплаты"
    }`,
    linkText: "Повторить",
  },
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock("svg", {
      class: _normalizeClass([`operation-result__icon--${
        __props.status === _unref(EPayStatus).SUCCESS ? 'success' : 'fail'
      }`, "operation-result__icon"])
    }, [
      _createElementVNode("use", {
        "xlink:href": resultInformation[__props.status].icon
      }, null, 8, _hoisted_2)
    ], 2)),
    _createElementVNode("span", _hoisted_3, _toDisplayString(resultInformation[__props.status].title), 1),
    (__props.url)
      ? (_openBlock(), _createBlock(VDivider, {
          key: 0,
          class: "divider-section"
        }))
      : _createCommentVNode("", true),
    (__props.url)
      ? (_openBlock(), _createElementBlock("a", {
          key: 1,
          href: __props.url,
          class: "button operation-result__link"
        }, _toDisplayString(resultInformation[__props.status].linkText), 9, _hoisted_4))
      : _createCommentVNode("", true)
  ]))
}
}

})