import { reactive } from "vue";
import { EModalType } from "@/helpers/enums";

type TModalProps = {
  isOpen: boolean;
  type: EModalType;
};
const modal = reactive<TModalProps>({
  isOpen: false,
  type: EModalType.TERMS,
});

export const useModal = () => {
  const onOpen = (type: EModalType) => {
    modal.isOpen = true;
    modal.type = type;
  };

  const onClose = () => {
    modal.isOpen = false;
  };

  return {
    modal,
    onOpen,
    onClose,
  };
};
