import { defineComponent as _defineComponent } from 'vue'
import { vModelDynamic as _vModelDynamic, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "input-wrapper" }
const _hoisted_2 = ["type"]

import { computed, PropType, ref, nextTick } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InputCvv',
  props: {
  modelValue: {
    type: String as PropType<string>,
    default: "",
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const type = ref<"text" | "password">("password");
const cvv = ref<HTMLInputElement | null>(null);

const focusToEndOfInput = async () => {
  cvv.value?.setSelectionRange(cvv.value.value.length, cvv.value.value.length);
  await nextTick();
  cvv.value?.focus();
};

const handleOnToggleType = () => {
  type.value = type.value === "text" ? "password" : "text";
  focusToEndOfInput();
};

return (_ctx: any,_cache: any) => {
  const _directive_cardformat = _resolveDirective("cardformat")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      ref_key: "cvv",
      ref: cvv,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
      type: type.value,
      class: "input input--cvv",
      placeholder: "CVC"
    }, null, 8, _hoisted_2), [
      [_vModelDynamic, value.value],
      [_directive_cardformat, void 0, "formatCardCVC"]
    ]),
    (value.value !== '')
      ? (_openBlock(), _createElementBlock("button", {
          key: 0,
          class: _normalizeClass([{ visible: type.value === 'text' }, "input-wrapper__toggle"]),
          type: "button",
          onClick: handleOnToggleType
        }, _cache[1] || (_cache[1] = [
          _createElementVNode("svg", null, [
            _createElementVNode("use", { "xlink:href": "#eye" })
          ], -1)
        ]), 2))
      : _createCommentVNode("", true)
  ]))
}
}

})