<template>
  <div class="countdown">
    <span class="countdown__time">
      {{ timeLeft.minutes }}m {{ timeLeft.seconds }}s
    </span>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from "vue";

const props = defineProps({
  dateTo: {
    type: String,
    required: true,
  },
});

const timeLeft = ref({
  hours: "00",
  minutes: "00",
  seconds: "00",
});

let timer: number;

const formatNumber = (x: number) => (x < 10 ? `0${x}` : `${x}`);
const updateTime = () => {
  const endDate = new Date(props.dateTo + "Z").getTime(); // Adding 'Z' to indicate UTC time

  const now = new Date().getTime();
  let difference = endDate - now;

  if (difference < 0) {
    clearInterval(timer);
    difference = 0;
  }

  timeLeft.value = {
    hours: formatNumber(
      Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    ),
    minutes: formatNumber(
      Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60))
    ),
    seconds: formatNumber(Math.floor((difference % (1000 * 60)) / 1000)),
  };
};

onMounted(() => {
  updateTime();
  timer = setInterval(updateTime, 1000);
});

onUnmounted(() => {
  clearInterval(timer);
});
</script>

<style lang="scss">
.countdown {
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;

  &__time {
    font-feature-settings: "tnum";
    font-variant-numeric: tabular-nums;
  }
}
</style>
