import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "operation" }

import { PropType } from "vue";
import { OperationType } from "@/helpers/enums";
import PriceHeader from "@/components/PriceHeader.vue";
import CardTypes from "@/components/CardTypes.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'OperationWrapper',
  props: {
  price: {
    type: String as PropType<string>,
    default: "",
  },
  type: {
    type: String as PropType<OperationType>,
    default: OperationType.PAYMENT,
  },
  hasCardsBlock: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (__props.price)
      ? (_openBlock(), _createBlock(PriceHeader, {
          key: 0,
          price: __props.price,
          type: __props.type,
          class: "price-section"
        }, null, 8, ["price", "type"]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default"),
    (__props.hasCardsBlock)
      ? (_openBlock(), _createBlock(CardTypes, { key: 1 }))
      : _createCommentVNode("", true)
  ]))
}
}

})