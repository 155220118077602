<template>
  <form class="upload" @submit.prevent>
    <div class="upload__container" @dragover.prevent @drop.prevent>
      <input
        id="file-upload"
        ref="inputFile"
        :accept="acceptedTypes.join(', ')"
        class="upload__input visibility-hidden"
        name="file-uploader"
        type="file"
        @change="getFile"
      />
      <label
        :class="{ dragover: dragOver }"
        class="upload__label"
        for="file-upload"
        @dragenter="toggleDragOver"
        @dragleave="toggleDragOver"
        @drop="dragFile"
      />
      <div class="upload__frame">
        <div class="upload__img">
          <img
            v-if="!uploadedImage"
            alt="document upload"
            class="upload__doc-icon"
            src="/doc.svg"
          />
          <img
            v-else-if="uploadedFile?.type !== 'application/pdf'"
            :src="uploadedImage as string"
            alt=""
            class="upload__image"
          />
          <p v-else>pdf</p>
        </div>
        <div class="upload__info">
          <p class="upload__drag-text">
            Перетащите файл в эту облать или <span>загрузите его</span>
          </p>
          <p class="upload__drag-text">JPG, JPEG, PNG или PDF</p>
        </div>
      </div>
    </div>
    <p v-if="error" class="upload__error" data-test="img-error">
      {{ error }}
    </p>
  </form>
</template>

<script lang="ts" setup>
import { PropType, ref, watchEffect } from "vue";

const emit = defineEmits<{
  (e: "update:modelValue", value: File | null): void;
}>();

const props = defineProps({
  modelValue: {
    type: File as PropType<File | null>,
    default: null,
  },
});

const maxFileSize = 1024 ** 2 * 10;
const acceptedTypes = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "application/pdf",
];

const dragOver = ref(false);
const uploadedImage = ref<string | ArrayBuffer | null | undefined>(null);
const error = ref("");
const uploadedFile = ref<File | null>(props.modelValue);

watchEffect(() => {
  uploadedFile.value = props.modelValue;
  if (!uploadedFile.value) {
    uploadedImage.value = null;
  }
});

const getFile = (e: Event) => {
  appendFile(((e.target as HTMLInputElement).files as FileList)[0]);
};

const dragFile = (e: DragEvent) => {
  appendFile(((e.dataTransfer as DataTransfer).files as FileList)[0]);
  dragOver.value = false;
};

const toggleDragOver = () => {
  dragOver.value = !dragOver.value;
};

const appendFile = (file: File) => {
  error.value = "";
  if (file.size > maxFileSize) {
    error.value = `Размер документа превышает ${maxFileSize / 1024 ** 2}Мб`;
    return;
  }
  if (acceptedTypes.includes(file.type)) {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      (e: ProgressEvent<FileReader>) => (uploadedImage.value = e.target?.result)
    );
    uploadedFile.value = file;
    emit("update:modelValue", file);

    reader.readAsDataURL(file);
  } else {
    emit("update:modelValue", null);
    error.value = "Допустимые форматы изображения: jpg, jpeg, png, pdf";
  }
};
</script>

<style lang="scss" scoped>
@import "@scss/utils";

.upload {
  &__container {
    position: relative;
    display: grid;
    align-content: center;
    margin-bottom: 20px;
    padding: 16px;
    border-radius: 10px;
  }

  &__input:focus-visible + label {
    outline: 1px solid white;
  }

  &__label {
    position: absolute;
    inset: 0;
    display: block;
    overflow: hidden;
    border: 1px dashed $white-650;
    border-radius: 6px;
    cursor: pointer;

    &::before {
      position: absolute;
      inset: 0;
      display: block;
      box-shadow: inset 0 0 38px 31px rgba($black, 0.4);
      transform: scale(2);
      opacity: 0;
      transition: transform 0.2s ease, opacity 0.2s ease;
      content: "";
    }

    &:hover {
      border-color: $link-text-hover;
    }

    &.dragover {
      &::before {
        transform: scale(1);
        opacity: 1;
      }
    }
  }

  &__frame {
    display: grid;
    grid-template-columns: 56px 1fr;
    gap: 16px;
    width: 100%;
  }

  &__img {
    display: grid;
    place-content: center;
    width: 56px;
    height: 56px;
    object-fit: cover;
    overflow: clip;
    border-radius: 6px;
    background-color: $wrapper-bg;
    margin-block: 5px;
  }

  &__doc-icon {
    width: 18px;
    height: 22px;
  }

  &__info {
    display: grid;
    gap: 8px;
  }

  &__drag-text {
    color: $alter-text;
    font-size: 14px;
    line-height: 20px;

    span {
      color: $link-text;
    }
  }

  &__loader {
    width: 24px;
    height: 24px;
  }

  &__error {
    margin-bottom: 10px;
    color: $error;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
  }
}
</style>
