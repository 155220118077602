import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "support" }
const _hoisted_2 = { class: "support__container support__container--info" }
const _hoisted_3 = { class: "support__container" }
const _hoisted_4 = { class: "support__number" }
const _hoisted_5 = { class: "support__error" }
const _hoisted_6 = { class: "support__buttons" }

import VDivider from "@/components/VDivider.vue";
import VButton from "@/components/VButton.vue";
import InputFile from "@/components/inputs/InputFile.vue";
import { PropType, ref } from "vue";
import { TAppealForm, TPaymentResponse } from "@/types/payment";
import { useApi } from "@/plugins/api";
import InfoBlock from "@/components/InfoBlock.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'H2HP2PSupport',
  props: {
  request: {
    type: Object as PropType<TPaymentResponse>,
    default: null,
  },
},
  emits: ["close", "updateAppeals"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const form = ref<TAppealForm | { receipt: File | null }>({
  receipt: null,
  tx: props.request?.info.tx,
});
const isLoading = ref(false);
const error = ref("");

const { payment } = useApi();

const sendAppeal = async () => {
  try {
    error.value = "";
    isLoading.value = true;
    await payment.createAppeal(form.value as TAppealForm);
    emit("updateAppeals");
    emit("close");
  } catch (e) {
    error.value =
      "Произошла непредвиденная ошибка, попробуйте еще раз или вернитесь позже";
    console.error(e);
  } finally {
    form.value.receipt = null;
    isLoading.value = false;
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(InfoBlock, {
        class: "support__info",
        title: "Внимание",
        type: "warning"
      }, {
        default: _withCtx(() => _cache[2] || (_cache[2] = [
          _createElementVNode("pre", null, "Уважаемый пользователь, если вы оплатили и депозит не был зачислен. Пожалуйста выберите Ваш чек об оплате и прикрепите его к этому обращению.\nЭто нужно для того, чтобы мы могли помочь Вам разобраться с этой проблемой в кратчайшие сроки.\n        ", -1)
        ])),
        _: 1
      }),
      _createVNode(InfoBlock, {
        class: "support__info",
        title: "Инструкция"
      }, {
        default: _withCtx(() => _cache[3] || (_cache[3] = [
          _createElementVNode("ol", null, [
            _createElementVNode("li", null, [
              _createTextVNode(" Сохраните ваш чек об оплате в JPG, JPEG, PNG или PDF. Убедитесь что: "),
              _createElementVNode("ul", null, [
                _createElementVNode("li", null, "Видно время оплаты"),
                _createElementVNode("li", null, "Видно отправителя и получателя"),
                _createElementVNode("li", null, "Видно сумму")
              ]),
              _createElementVNode("span", null, [
                _createTextVNode(" Пример: "),
                _createElementVNode("a", {
                  href: "/transaction-example.jpg",
                  target: "_blank"
                }, "Чек 1")
              ])
            ]),
            _createElementVNode("li", null, "Загрузите чек"),
            _createElementVNode("li", null, "После того как Вы загрузили чек нажмите \"Отправить\"")
          ], -1)
        ])),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _cache[5] || (_cache[5] = _createElementVNode("h2", { class: "support__title" }, "Обращение в службу поддержки", -1)),
      _cache[6] || (_cache[6] = _createElementVNode("span", { class: "support__payment-number" }, " Номер платежа ", -1)),
      _createElementVNode("p", _hoisted_4, _toDisplayString(__props.request.info.tx), 1),
      _createVNode(InputFile, {
        modelValue: form.value.receipt,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.value.receipt) = $event))
      }, null, 8, ["modelValue"]),
      _cache[7] || (_cache[7] = _createElementVNode("p", { class: "support__rules" }, "Видно получателя, отправителя и сумму", -1)),
      _cache[8] || (_cache[8] = _createElementVNode("span", { class: "support__example" }, [
        _createTextVNode(" Пример: "),
        _createElementVNode("a", {
          href: "/transaction-example.jpg",
          target: "_blank"
        }, "Чек 1")
      ], -1)),
      _createElementVNode("p", _hoisted_5, _toDisplayString(error.value), 1),
      _createVNode(VDivider, { class: "divider-section" }),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("button", {
          class: "support__btn",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (emit('close')))
        }, "Отмена"),
        _createVNode(VButton, {
          disabled: !form.value.receipt,
          loading: isLoading.value,
          onClick: sendAppeal
        }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode("Отправить ")
          ])),
          _: 1
        }, 8, ["disabled", "loading"])
      ])
    ])
  ]))
}
}

})