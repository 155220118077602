<template>
  <div class="modal" @click.self="handleOnHide">
    <div class="modal__container" @click.stop>
      <button class="modal__close" @click="handleOnHide">
        <svg>
          <use xlink:href="#close" />
        </svg>
      </button>
      <div class="modal__title">
        <slot name="title" />
      </div>
      <div class="modal__body">
        <slot />
      </div>
      <TheFooter />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, onUnmounted } from "vue";
import TheFooter from "@/components/TheFooter.vue";

const emit = defineEmits<{
  (event: "hide"): void;
}>();

const handleOnHide = () => emit("hide");

onMounted(() => {
  document.getElementsByTagName("html")[0].classList.add("modal-lock");
});

onUnmounted(() => {
  document.getElementsByTagName("html")[0].classList.remove("modal-lock");
});
</script>

<style lang="scss">
@import "@scss/utils.scss";

.modal {
  position: fixed;
  inset: 0;
  display: flex;
  padding: 20px;
  background: rgba($black, 0.3);

  &__container {
    position: relative;
    max-width: 475px;
    margin: auto;
    padding: 32px;
    border-radius: 20px;
    background-color: $alter-bg;

    @include mq($sz-mobile) {
      padding: 16px;
    }
  }

  &__title {
    margin-bottom: 50px;
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;

    @include mq($sz-mobile) {
      margin-bottom: 24px;
    }
  }

  &-lock {
    overflow: hidden;

    body {
      overflow-y: scroll;
    }
  }

  &__body {
    max-height: 60vh;
    margin-bottom: 32px;
    padding-right: 15px;
    overflow: auto;

    @include mq($sz-mobile) {
      max-height: 70vh;
    }

    @include vertical-scroll($gray-800, $white-650);
  }

  &__close {
    position: absolute;
    top: 32px;
    right: 25px;
    display: grid;
    place-content: center;
    width: 37px;
    height: 37px;
    border: none;
    background: none;
    cursor: pointer;

    @include mq($sz-mobile) {
      top: 16px;
      right: 5px;
    }

    svg {
      width: 18px;
      height: 18px;
    }
  }
}
</style>
