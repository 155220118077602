import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "form approve" }
const _hoisted_2 = { class: "form__container form__container--p2p" }
const _hoisted_3 = { class: "approve__wrapper" }
const _hoisted_4 = {
  key: 0,
  class: "approve__copied"
}

import { OperationType } from "@/helpers/enums";
import OperationWrapper from "@/components/OperationWrapper.vue";
import VCountdown from "@/components/VCountdown.vue";
import VDivider from "@/components/VDivider.vue";
import VButton from "@/components/VButton.vue";
import { PropType, ref } from "vue";
import { TPaymentResponse } from "@/types/payment";
import { usePrice } from "@/use/usePrice";
import InputWrapper from "@/components/inputs/InputWrapper.vue";
import InputCard from "@/components/inputs/InputCard.vue";
import { TCardTypes, TNullable } from "@/types/common";


export default /*@__PURE__*/_defineComponent({
  __name: 'P2PInstructions',
  props: {
  request: {
    type: Object as PropType<TPaymentResponse>,
    default: null,
  },
},
  setup(__props) {

const props = __props;

const price = usePrice(
  props.request?.info?.payment?.amount ?? 0,
  props.request?.info?.payment?.currency?.char_code
);
const cardNumber = ref<string>(localStorage.getItem("p2p.card") || "");
const payExpire = ref<string>(localStorage.getItem("p2p.expires") || "");
const cardType = ref<TNullable<TCardTypes>>("unknown");
const isCopied = ref(false);

const copyToClipboard = async () => {
  await navigator.clipboard.writeText(cardNumber.value);
  isCopied.value = true;
  setTimeout(() => (isCopied.value = false), 2000);
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(OperationWrapper, {
    price: _unref(price),
    type: _unref(OperationType).PAYMENT,
    "has-cards-block": ""
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _cache[4] || (_cache[4] = _createElementVNode("p", { class: "approve__head" }, "Переведите деньги на эту карту:", -1)),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(InputWrapper, { "card-type": cardType.value }, {
              default: _withCtx(() => [
                _createVNode(InputCard, {
                  modelValue: cardNumber.value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((cardNumber).value = $event)),
                  "onUpdate:cardType": _cache[1] || (_cache[1] = ($event: any) => (cardType.value = $event)),
                  disabled: "true"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["card-type"]),
            _createElementVNode("button", {
              class: "approve__copy",
              onClick: _withModifiers(copyToClipboard, ["prevent"])
            }, _cache[3] || (_cache[3] = [
              _createElementVNode("svg", null, [
                _createElementVNode("use", { "xlink:href": "#copy" })
              ], -1)
            ]))
          ]),
          (isCopied.value)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, "Скопировано!"))
            : _createCommentVNode("", true),
          _createVNode(VCountdown, {
            class: "approve__countdown",
            "date-to": payExpire.value
          }, null, 8, ["date-to"])
        ]),
        _createVNode(VDivider, { class: "divider-section" }),
        (__props.request)
          ? (_openBlock(), _createBlock(VButton, {
              key: 0,
              class: "approve__submit",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('complete')))
            }, {
              default: _withCtx(() => _cache[5] || (_cache[5] = [
                _createTextVNode(" Проверить ")
              ])),
              _: 1
            }))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["price", "type"]))
}
}

})