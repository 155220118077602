import api from "@/plugins/api";
import { App } from "vue";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VueCreditCardValidation from "@alekseyburn/vue-credit-card-validation";

export default {
  install(app: App) {
    app.use(api);
    app.use(VueCreditCardValidation);
  },
};
