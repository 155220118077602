<template>
  <div class="card-types">
    <svg v-for="(card, i) of cardTypes" :key="i" class="card-types__icon">
      <use :xlink:href="`#${card}`" />
    </svg>
  </div>
</template>

<script lang="ts" setup>
const cardTypes: string[] = [
  "card-world",
  "master-card",
  "visa-card",
  "pci-card",
];
</script>

<style lang="scss">
.card-types {
  display: grid;
  grid-auto-flow: column;
  place-content: center;
  column-gap: 16px;

  &__icon {
    width: 54px;
    height: 24px;
  }
}
</style>
