import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "operation-fail" }
const _hoisted_2 = ["href"]

import { PropType } from "vue";
import VButton from "@/components/VButton.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'OperationFail',
  props: {
  url: {
    type: String as PropType<string>,
    default: "",
  },
  hasAppeals: {
    type: Boolean,
    default: false,
  },
},
  emits: ["openAppeal", "openAppealStatus"],
  setup(__props, { emit: __emit }) {



const emit = __emit;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[4] || (_cache[4] = _createElementVNode("svg", { class: "operation-fail__icon" }, [
      _createElementVNode("use", { "xlink:href": "#fail-payment" })
    ], -1)),
    _cache[5] || (_cache[5] = _createElementVNode("span", { class: "operation-fail__title" }, "Ошибка оплаты", -1)),
    (__props.url)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          href: __props.url,
          class: "button operation-fail__link"
        }, " Повторить ", 8, _hoisted_2))
      : _createCommentVNode("", true),
    (!__props.hasAppeals)
      ? (_openBlock(), _createBlock(VButton, {
          key: 1,
          class: "operation-fail__link",
          transparent: "",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('openAppeal')))
        }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode(" Обратиться в поддержку ")
          ])),
          _: 1
        }))
      : (_openBlock(), _createBlock(VButton, {
          key: 2,
          class: "operation-fail__link",
          transparent: "",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (emit('openAppealStatus')))
        }, {
          default: _withCtx(() => _cache[3] || (_cache[3] = [
            _createTextVNode(" Посмотреть статус апелляции ")
          ])),
          _: 1
        }))
  ]))
}
}

})