import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["accept"]
const _hoisted_2 = { class: "upload__frame" }
const _hoisted_3 = { class: "upload__img" }
const _hoisted_4 = {
  key: 0,
  alt: "document upload",
  class: "upload__doc-icon",
  src: "/doc.svg"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = { key: 2 }
const _hoisted_7 = {
  key: 0,
  class: "upload__error",
  "data-test": "img-error"
}

import { PropType, ref, watchEffect } from "vue";

const maxFileSize = 1024 ** 2 * 10;

export default /*@__PURE__*/_defineComponent({
  __name: 'InputFile',
  props: {
  modelValue: {
    type: File as PropType<File | null>,
    default: null,
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const props = __props;

const acceptedTypes = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "application/pdf",
];

const dragOver = ref(false);
const uploadedImage = ref<string | ArrayBuffer | null | undefined>(null);
const error = ref("");
const uploadedFile = ref<File | null>(props.modelValue);

watchEffect(() => {
  uploadedFile.value = props.modelValue;
  if (!uploadedFile.value) {
    uploadedImage.value = null;
  }
});

const getFile = (e: Event) => {
  appendFile(((e.target as HTMLInputElement).files as FileList)[0]);
};

const dragFile = (e: DragEvent) => {
  appendFile(((e.dataTransfer as DataTransfer).files as FileList)[0]);
  dragOver.value = false;
};

const toggleDragOver = () => {
  dragOver.value = !dragOver.value;
};

const appendFile = (file: File) => {
  error.value = "";
  if (file.size > maxFileSize) {
    error.value = `Размер документа превышает ${maxFileSize / 1024 ** 2}Мб`;
    return;
  }
  if (acceptedTypes.includes(file.type)) {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      (e: ProgressEvent<FileReader>) => (uploadedImage.value = e.target?.result)
    );
    uploadedFile.value = file;
    emit("update:modelValue", file);

    reader.readAsDataURL(file);
  } else {
    emit("update:modelValue", null);
    error.value = "Допустимые форматы изображения: jpg, jpeg, png, pdf";
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("form", {
    class: "upload",
    onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
  }, [
    _createElementVNode("div", {
      class: "upload__container",
      onDragover: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"])),
      onDrop: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent"]))
    }, [
      _createElementVNode("input", {
        id: "file-upload",
        ref: "inputFile",
        accept: acceptedTypes.join(', '),
        class: "upload__input visibility-hidden",
        name: "file-uploader",
        type: "file",
        onChange: getFile
      }, null, 40, _hoisted_1),
      _createElementVNode("label", {
        class: _normalizeClass([{ dragover: dragOver.value }, "upload__label"]),
        for: "file-upload",
        onDragenter: toggleDragOver,
        onDragleave: toggleDragOver,
        onDrop: dragFile
      }, null, 34),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (!uploadedImage.value)
            ? (_openBlock(), _createElementBlock("img", _hoisted_4))
            : (uploadedFile.value?.type !== 'application/pdf')
              ? (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  src: uploadedImage.value as string,
                  alt: "",
                  class: "upload__image"
                }, null, 8, _hoisted_5))
              : (_openBlock(), _createElementBlock("p", _hoisted_6, "pdf"))
        ]),
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "upload__info" }, [
          _createElementVNode("p", { class: "upload__drag-text" }, [
            _createTextVNode(" Перетащите файл в эту облать или "),
            _createElementVNode("span", null, "загрузите его")
          ]),
          _createElementVNode("p", { class: "upload__drag-text" }, "JPG, JPEG, PNG или PDF")
        ], -1))
      ])
    ], 32),
    (error.value)
      ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(error.value), 1))
      : _createCommentVNode("", true)
  ], 32))
}
}

})