<template>
  <input
    class="input"
    v-model="value"
    placeholder="Введите номер кошелька"
    @keypress="handleOnKeyPress"
    @paste="handleOnPaste"
    @input="handleOnChange"
  />
</template>

<script lang="ts" setup>
import { computed, PropType } from "vue";

const props = defineProps({
  modelValue: {
    type: String as PropType<string>,
    default: "",
  },
});

const emit = defineEmits(["update:modelValue"]);

const value = computed<string>({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});

const validateLatinAndNumbers = (
  e: KeyboardEvent | ClipboardEvent,
  value: string
) => {
  if (/^[A-Za-z\d\s]+$/.test(value)) return true;
  else e.preventDefault();
};

const handleOnPaste = (e: ClipboardEvent) =>
  validateLatinAndNumbers(e, (e.clipboardData as DataTransfer).getData("text"));

const handleOnKeyPress = (e: KeyboardEvent) =>
  validateLatinAndNumbers(e, String.fromCharCode(e.keyCode));

const handleOnChange = () => {
  value.value = value.value.replace(/[^A-Za-z\d]/g, "");
};
</script>

<style lang="scss">
@import "input";
</style>
