<template>
  <div :class="`info-block--${type}`" class="info-block">
    <div class="info-block__head">
      <component :is="icons[type]" />
      <h2>{{ title }}</h2>
    </div>
    <slot />
  </div>
</template>

<script lang="ts" setup>
import VInfo from "@/components/icons/VInfo.vue";
import VAlert from "@/components/icons/VAlert.vue";
import VSuccess from "@/components/icons/VSuccess.vue";

withDefaults(
  defineProps<{
    type?: "warning" | "info" | "success" | "error";
    title: string;
  }>(),
  {
    type: "info",
  }
);

const icons = {
  warning: VAlert,
  info: VInfo,
  success: VSuccess,
  error: VAlert,
};
</script>

<style lang="scss">
@import "@scss/utils";

.info-block {
  padding: 16px;
  border-radius: 10px;
  background-color: $wrapper-bg;

  ol,
  ul {
    list-style-position: inside;
  }

  ul {
    padding-left: 18px;
    list-style-type: disc;
  }

  > pre,
  ol {
    display: grid;
    font-size: 12px;
    font-family: inherit;
    line-height: 18px;
    white-space: pre-wrap;
    row-gap: 8px;

    > li {
      > span {
        padding-left: 18px;
      }
    }
  }

  &__head {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 8px;
    align-items: center;

    &:not(:only-child) {
      margin-bottom: 16px;
    }

    > svg {
      width: 24px;
      height: 24px;
      color: $info;
    }

    > h2 {
      color: $alter-text;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
    }
  }

  &--warning {
    background-color: rgba($warning, 0.1);

    & .info-block__head {
      > svg {
        color: $warning;
      }
    }
  }

  &--error {
    background-color: rgba($error, 0.1);

    & .info-block__head {
      > svg {
        color: $error;
      }
    }
  }

  &--success {
    background-color: #daf6e3;

    & .info-block__head {
      > svg {
        color: $success;
      }
    }
  }
}
</style>
