import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "info-block__head" }

import VInfo from "@/components/icons/VInfo.vue";
import VAlert from "@/components/icons/VAlert.vue";
import VSuccess from "@/components/icons/VSuccess.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InfoBlock',
  props: {
    type: { default: "info" },
    title: {}
  },
  setup(__props: any) {



const icons = {
  warning: VAlert,
  info: VInfo,
  success: VSuccess,
  error: VAlert,
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([`info-block--${_ctx.type}`, "info-block"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(), _createBlock(_resolveDynamicComponent(icons[_ctx.type]))),
      _createElementVNode("h2", null, _toDisplayString(_ctx.title), 1)
    ]),
    _renderSlot(_ctx.$slots, "default")
  ], 2))
}
}

})