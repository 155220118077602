import { computed, getCurrentInstance, reactive } from "vue";
import { TValidFields } from "@/types/common";

const validFields = reactive<TValidFields>({
  pan: false,
  expire: false,
  cvv: false,
  holder: false,
  account_number: false,
});

type TFields = "pan" | "expire" | "cvv" | "holder" | "account_number";

const validateFields = (fields: TFields[]) => {
  for (const field of fields) {
    if (!validFields[field]) return false;
  }
  return true;
};

const isPayFormValid = computed(() =>
  validateFields(["pan", "expire", "cvv", "holder"])
);
const isP2PFormValid = computed(() => validateFields(["pan"]));
const isPayoutFormValid = computed(() => validateFields(["pan"]));
const isCryptoPayoutFormValid = computed(() =>
  validateFields(["account_number"])
);

export const useValidation = () => {
  const app = getCurrentInstance();
  const cardFormat = app?.appContext?.config?.globalProperties?.$cardFormat;
  const validatePan = (value: string) => {
    validFields.pan = cardFormat.validateCardNumber(value);
  };
  const validateExpiry = (value: string) =>
    (validFields.expire = cardFormat.validateCardExpiry(value));
  const validateCVV = (value: string) => {
    validFields.cvv = cardFormat.validateCardCVC(value);
  };
  const validateHolder = (value: string) => {
    const [name, surname, ...args] = value?.split(" ") ?? [];
    validFields.holder = !!(name?.length && surname?.length && !args?.length);
  };
  const validateCryptoWallet = (value: string) =>
    (validFields.account_number = value.length > 15);

  return {
    validFields,
    isPayFormValid,
    isP2PFormValid,
    isPayoutFormValid,
    isCryptoPayoutFormValid,
    validatePan,
    validateCVV,
    validateExpiry,
    validateHolder,
    validateCryptoWallet,
  };
};
