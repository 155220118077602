<template>
  <div class="operation-fail">
    <svg class="operation-fail__icon">
      <use xlink:href="#fail-payment" />
    </svg>
    <span class="operation-fail__title">Ошибка оплаты</span>
    <a v-if="url" :href="url" class="button operation-fail__link">
      Повторить
    </a>
    <VButton
      v-if="!hasAppeals"
      class="operation-fail__link"
      transparent
      @click="emit('openAppeal')"
    >
      Обратиться в поддержку
    </VButton>
    <VButton
      v-else
      class="operation-fail__link"
      transparent
      @click="emit('openAppealStatus')"
    >
      Посмотреть статус апелляции
    </VButton>
  </div>
</template>

<script lang="ts" setup>
import { PropType } from "vue";
import VButton from "@/components/VButton.vue";

defineProps({
  url: {
    type: String as PropType<string>,
    default: "",
  },
  hasAppeals: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits<{
  (e: "openAppeal"): void;
  (e: "openAppealStatus"): void;
}>();
</script>

<style lang="scss" scoped>
@import "@scss/utils.scss";

.operation-fail {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 18px;
  text-align: center;

  @include mq($sz-mobile) {
    flex-grow: 1;
    margin-top: 50%;
  }

  &__icon {
    width: 52px;
    height: 52px;
    margin-bottom: 46px;
    animation: headShake 1s ease-in-out;
  }

  &__title {
    @include font-template(Poppins, 32px, 40px, 500);
  }

  &__link {
    margin-top: 24px;
  }
}

@keyframes headShake {
  0% {
    transform: translateX(0);
  }

  6.5% {
    transform: translateX(-6px) rotateY(-9deg);
  }

  18.5% {
    transform: translateX(5px) rotateY(7deg);
  }

  31.5% {
    transform: translateX(-3px) rotateY(-5deg);
  }

  43.5% {
    transform: translateX(2px) rotateY(3deg);
  }

  50% {
    transform: translateX(0);
  }
}
</style>
