<template>
  <div class="support">
    <div class="support__container support__container--info">
      <info-block class="support__info" title="Внимание" type="warning">
        <pre>
Уважаемый пользователь, если вы оплатили и депозит не был зачислен. Пожалуйста выберите Ваш чек об оплате и прикрепите его к этому обращению.
Это нужно для того, чтобы мы могли помочь Вам разобраться с этой проблемой в кратчайшие сроки.
        </pre>
      </info-block>
      <info-block class="support__info" title="Инструкция">
        <ol>
          <li>
            Сохраните ваш чек об оплате в JPG, JPEG, PNG или PDF. Убедитесь что:
            <ul>
              <li>Видно время оплаты</li>
              <li>Видно отправителя и получателя</li>
              <li>Видно сумму</li>
            </ul>
            <span>
              Пример:
              <a href="/transaction-example.jpg" target="_blank">Чек 1</a>
            </span>
          </li>
          <li>Загрузите чек</li>
          <li>После того как Вы загрузили чек нажмите "Отправить"</li>
        </ol>
      </info-block>
    </div>
    <div class="support__container">
      <h2 class="support__title">Обращение в службу поддержки</h2>
      <span class="support__payment-number"> Номер платежа </span>
      <p class="support__number">{{ request.info.tx }}</p>
      <input-file v-model="form.receipt" />
      <p class="support__rules">Видно получателя, отправителя и сумму</p>
      <span class="support__example">
        Пример:
        <a href="/transaction-example.jpg" target="_blank">Чек 1</a>
      </span>
      <p class="support__error">{{ error }}</p>
      <v-divider class="divider-section" />
      <div class="support__buttons">
        <button class="support__btn" @click="emit('close')">Отмена</button>
        <VButton
          :disabled="!form.receipt"
          :loading="isLoading"
          @click="sendAppeal"
          >Отправить
        </VButton>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import VDivider from "@/components/VDivider.vue";
import VButton from "@/components/VButton.vue";
import InputFile from "@/components/inputs/InputFile.vue";
import { PropType, ref } from "vue";
import { TAppealForm, TPaymentResponse } from "@/types/payment";
import { useApi } from "@/plugins/api";
import InfoBlock from "@/components/InfoBlock.vue";

const props = defineProps({
  request: {
    type: Object as PropType<TPaymentResponse>,
    default: null,
  },
});

const emit = defineEmits<{
  (e: "close"): void;
  (e: "updateAppeals"): void;
}>();

const form = ref<TAppealForm | { receipt: File | null }>({
  receipt: null,
  tx: props.request?.info.tx,
});
const isLoading = ref(false);
const error = ref("");

const { payment } = useApi();

const sendAppeal = async () => {
  try {
    error.value = "";
    isLoading.value = true;
    await payment.createAppeal(form.value as TAppealForm);
    emit("updateAppeals");
    emit("close");
  } catch (e) {
    error.value =
      "Произошла непредвиденная ошибка, попробуйте еще раз или вернитесь позже";
    console.error(e);
  } finally {
    form.value.receipt = null;
    isLoading.value = false;
  }
};
</script>

<style lang="scss">
@import "@scss/utils";

.support {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 26px;

  @include mq(600px) {
    gap: 16px;
  }

  @include mq($sz-mobile) {
    grid-template-columns: unset;
    max-width: none;
    padding: 16px;
    border-radius: 20px;
    background-color: $alter-bg;
    box-shadow: 0 12px 24px rgba($shadow-modal, 0.08);
  }

  &__container {
    display: grid;
    align-content: start;

    &--info {
      gap: 26px;

      @include mq(600px) {
        gap: 16px;
      }
    }
  }

  &__title {
    margin-bottom: 16px;
    font-size: 18px;
    line-height: 28px;
  }

  &__payment-number,
  &__status-text {
    margin-bottom: 4px;
    color: $alter-text;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;
  }

  &__number {
    margin-bottom: 24px;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
  }

  &__rules {
    margin-bottom: 8px;
    color: $alter-text;
    font-size: 14px;
    line-height: 20px;
  }

  &__example {
    color: $alter-text;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.3px;

    > a {
      color: $link-text;
      text-decoration: underline;
      cursor: pointer;

      &:hover,
      &:focus {
        color: $link-text-hover;
      }
    }
  }

  &__buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    @include mq($sz-mobile) {
      grid-template-columns: 1fr;
      margin-top: 16px;
    }
  }

  &__btn {
    padding: 18px;
    color: $btn-bg-main;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.3px;
    text-align: center;

    &:hover,
    &:focus {
      color: $btn-bg-hover;
    }

    @include mq($sz-mobile) {
      order: 1;
    }
  }

  &__error {
    margin-top: 16px;
    color: $error;
    font-size: 14px;
    line-height: 18px;
  }

  &__divider {
    margin-block: 26px;
  }
}
</style>
