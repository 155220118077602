import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "operation-success" }
const _hoisted_2 = ["href"]

import { PropType } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'OperationSuccess',
  props: {
  url: {
    type: String as PropType<string>,
    default: "",
  },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("svg", { class: "operation-success__icon" }, [
      _createElementVNode("use", { "xlink:href": "#success-payment" })
    ], -1)),
    _cache[1] || (_cache[1] = _createElementVNode("span", { class: "operation-success__title" }, "Оплачено", -1)),
    (__props.url)
      ? (_openBlock(), _createElementBlock("a", {
          key: 0,
          href: __props.url,
          class: "button operation-success__link"
        }, " Вернуться в магазин ", 8, _hoisted_2))
      : _createCommentVNode("", true)
  ]))
}
}

})