<template>
  <p2-p-instructions
    v-if="isP2pPayment"
    :request="paymentResponse"
    @complete="payed = true"
  />
  <the-loader v-else />
</template>

<script lang="ts" setup>
import { computed, onMounted, onUnmounted, ref } from "vue";
import { usePayment } from "@/use/usePayment";
import { EIntegrationType, EPayStatus } from "@/helpers/enums";
import TheLoader from "@/components/TheLoader.vue";
import P2PInstructions from "@/components/form/P2PInstructions.vue";

const emit = defineEmits(["fail", "success"]);

const { paymentInfo, paymentResponse, status } = usePayment();
let interval = 0;

const payed = ref<boolean>(false);
const isP2pPayment = computed(
  () =>
    paymentResponse.value?.info.payment.integration_type ===
      EIntegrationType.P2P_CARD && !payed.value
);

const handleCheckPayment = async () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  await paymentInfo(params.tx);
  if (status.value === EPayStatus.FAIL) {
    emit("fail");
  }
  if (status.value === EPayStatus.SUCCESS) {
    emit("success");
  }
};

onMounted(() => {
  interval = setInterval(handleCheckPayment, 5000);
});

onUnmounted(() => {
  clearInterval(interval);
});
</script>
