<template>
  <input
    class="input"
    v-model="value"
    v-cardformat:formatCardExpiry
    placeholder="MM / YY"
  />
</template>

<script lang="ts" setup>
import { computed, PropType } from "vue";

const props = defineProps({
  modelValue: {
    type: String as PropType<string>,
    default: "",
  },
});

const emit = defineEmits(["update:modelValue"]);

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});
</script>

<style lang="scss">
@import "input";
</style>
