<template>
  <hr class="divider" />
</template>

<style lang="scss">
@import "@scss/utils.scss";

.divider {
  display: block;
  width: 100%;
  height: 1px;
  background-color: $divider;
}
</style>
