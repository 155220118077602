<template>
  <div class="loader">
    <img src="/spinner.png" alt="loader" />
  </div>
</template>

<style scoped lang="scss">
@import "@scss/utils.scss";

.loader {
  padding: 178px 142px;

  @include mq($sz-mobile) {
    margin: auto;
    padding: 0 0 52px;
  }

  img {
    animation: spin 1s infinite linear, fadeOut 4s infinite linear 2s;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  12.5% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
}
</style>
