import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { computed, onMounted, onUnmounted, ref } from "vue";
import { usePayment } from "@/use/usePayment";
import { EIntegrationType, EPayStatus } from "@/helpers/enums";
import TheLoader from "@/components/TheLoader.vue";
import P2PInstructions from "@/components/form/P2PInstructions.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProcessPayment',
  emits: ["fail", "success"],
  setup(__props, { emit: __emit }) {

const emit = __emit;

const { paymentInfo, paymentResponse, status } = usePayment();
let interval = 0;

const payed = ref<boolean>(false);
const isP2pPayment = computed(
  () =>
    paymentResponse.value?.info.payment.integration_type ===
      EIntegrationType.P2P_CARD && !payed.value
);

const handleCheckPayment = async () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  await paymentInfo(params.tx);
  if (status.value === EPayStatus.FAIL) {
    emit("fail");
  }
  if (status.value === EPayStatus.SUCCESS) {
    emit("success");
  }
};

onMounted(() => {
  interval = setInterval(handleCheckPayment, 5000);
});

onUnmounted(() => {
  clearInterval(interval);
});

return (_ctx: any,_cache: any) => {
  return (isP2pPayment.value)
    ? (_openBlock(), _createBlock(P2PInstructions, {
        key: 0,
        request: _unref(paymentResponse),
        onComplete: _cache[0] || (_cache[0] = ($event: any) => (payed.value = true))
      }, null, 8, ["request"]))
    : (_openBlock(), _createBlock(TheLoader, { key: 1 }))
}
}

})